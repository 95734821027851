import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Questionnare from "./Questionnare";
import DoctorSelect from "./DoctorSelect";
import { useDispatch, useSelector } from "react-redux";
import ViewImageModal from "./ViewImageModal";
import { getAdmins } from "../../Redux/Actions/admin/adminPanel";
import { Decryptedid } from "../../Utils/bcrypt";
import { Notes } from "@mui/icons-material";

const ConsNotes = ({ socket, consInfo }) => {
  const IMG = process.env.REACT_APP_HOST_NAME;
  const [notes, setNotes] = useState();
  const [notes1, setNotes1] = useState();
  const [viewModalImg, setViewModalImg] = useState(false);
  const [userNotes, setUserNotes] = useState();
  const [convoId, setConvoId] = useState();
  const [view, setView] = useState("Notes");
  const dispatch = useDispatch();
  const { id } = useParams();
  const consultantId = id && Decryptedid(atob(id));
  const admins = useSelector((state) => state.adminPanel.admins);

  const image1 = [IMG + "public/uploads/" + consInfo?.cons?.consNotesImage[0]];
  useEffect(() => {
    socket.on("recv_notes", (notesObject) => {
      setUserNotes(notesObject?.fromUser);
    });
  }, [socket]);

  useEffect(() => {
    const handleReceiveNotes = (notesObject) => {
      setUserNotes(notesObject?.fromUser);
    };

    socket.on("recv_notes", handleReceiveNotes);

    return () => {
      socket.off("recv_notes", handleReceiveNotes); // Cleanup listener on unmount
    };
  }, [socket]);

  useEffect(() => {
    dispatch(getAdmins());
  }, []);

  useEffect(() => {
    if (consultantId == consInfo?.cons?._id) {
      const docNotes = consInfo?.cons?.fromDoctor;
      setNotes(docNotes);
      const userNotes = consInfo?.cons?.fromUser;
      setUserNotes(userNotes);
      if (consInfo?.chat?.conversationId) {
        const convo = consInfo?.chat?.conversationId;
        setConvoId(convo);
        (async () => await socket.emit("join_room", convo))();
      }
    }
  }, [id, consInfo]);

  // const sendNotes = (e) => {
  //   if (e.key === "Enter") {
  //     const notesObject = {
  //       convoId: convoId,
  //       consId: consInfo?.cons?._id,
  //       fromDoctor: notes,
  //     };
  //     try {
  //       socket.emit("add_notes", notesObject);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };
  const sendNotes = () => {
    if (!notes1 || notes1.trim() === "") return; // Prevent sending empty notes

    const notesObject = {
      convoId: convoId,
      consId: consInfo?.cons?._id,
      fromDoctor: notes1,
    };

    try {
      setNotes((prevNotes) => (prevNotes ? `${prevNotes}\n${notes1}` : notes1));
      socket.emit("add_notes", notesObject);
       setNotes1(""); 
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="col-lg-5 upcoming_consultation consultationNotes h-700">
        <div className="chat_buttons d-flex tabs_margin justify-content-between mb-4">
          <div className="d-flex notes_btn">
            <button
              onClick={() => setView("Notes")}
              className={view == "Notes" ? "cre_new blue_btn " : "white-btn"}
            >
              Notes
            </button>
            <button
              onClick={() => setView("Question")}
              className={
                view == "Question" ? "cre_new blue_btn m-0" : "white-btn"
              }
            >
              Questionnaire
            </button>
          </div>
          {admins && <DoctorSelect consInfo={consInfo} admins={admins} />}
        </div>
        {view == "Notes" ? (
          <div className="card">
            <div className="">
              <div className="consulatation_card doctorNotes">
                <div className="upcoming_con_title">
                  <div className="image_xray_view">
                    <h3 className="common_title p-0">Consultation Notes</h3>
                    {consInfo?.cons?.consNotesImage?.length > 0 ? (
                      <button onClick={() => setViewModalImg(true)}>
                        View Upload
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                  <p>Notes for Doctor</p>
                </div>

                <div className="notes consult_notes">
                  {/* <textarea
                  className="form-control"
                  readOnly
                  value={userNotes}
                ></textarea> */}
                  <p className="mb-0  overflow-auto cons_notes_height">{userNotes}</p>
                </div>
              </div>
              <div className="consulatation_card notes_height height-ad-chat-show">
                <div className="upcoming_con_title ">
                  <p>Notes for your patient</p>
                </div>

                <div className="">
                  <div className="textarea notes_input ">

                    <div className="notes notes_input h-0 mb-0">
                      <p className="mb-0  overflow-auto cons_notes_height">{notes}</p>
                    </div>
                    <div className="notes notes_input  textarea-sec h-0 pt-0 pb-0">
                      <textarea
                        // rows={10}
                        className="form-control textarea-box"
                        placeholder="Type Here..."
                        onChange={(e) => setNotes1(e.target.value)}
                       value={notes1}
                      // onKeyDown={sendNotes}
                      ></textarea>
                      <div className="doctor-send-btn">
                        <button
                          type="button"
                          className="cre_new blue_btn"
                          name="submit"
                          // value="Send"
                          onClick={sendNotes} // Add onClick handler here
                        >
                          Send
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          view == "Question" &&
          consInfo?.medHistory && (
            <Questionnare medHistory={consInfo?.medHistory} />
          )
        )}
      </div>
      {viewModalImg && (
        <ViewImageModal
          viewModalImg={viewModalImg}
          setViewModalImg={setViewModalImg}
          image1={image1}
        />
      )}
    </>
  );
};

export default ConsNotes;
