import React, { useEffect, useState, useRef } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import Select from "react-select";
import debounce from "lodash.debounce";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Main from "../Main/Main";
import searchImg from "../../Assets/Icons/search.svg";
import ViewPrescription from "../View Prescription/ViewPrescription";
import { createPrescription, getClientListNewForClynxx, getPrescriptions } from "../../Redux/Actions/user/userAll";
import { formatDateToDMY } from "../../Utils/commonFiles";
import { useReactToPrint } from "react-to-print";

export default function AddPrescription() {
  const [showModal, setShowModal] = useState(false);
  const [viewP, setViewP] = useState();
  const [medsArray, setMedsArray] = useState([]);
  const [code, setCode] = useState("");
  const [grandTotal, setGrandTotal] = useState(0);
  const [formData, setFormData] = useState({});
  const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  const [isSecondModalOpen, setIsSecondModalOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [hasTyped, setHasTyped] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState({})   // for our db not in clynxx db
  const [medicineName, setMedicineName] = useState({})

  const PrescriptionRef = useRef();
  const handlePresPrint = useReactToPrint({
    content: () => PrescriptionRef.current,
  });

  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const prescriptions = useSelector((state) => state.consultaions.prescriptions);
  const clientList = useSelector((state) => state.consultaions.clientList);
  const data = useSelector((state) => state.consultaions.clynxxPatients);
  const profileDetails = useSelector((state) => state.consultaions.profilePic);

  useEffect(() => {
    if (id === "add") return;
    let data = { id };
    dispatch(getPrescriptions(data));
  }, [id, dispatch]);

  // useEffect(() => {
  //   let data = { page: 1, limit: 10 };
  //   dispatch(getClientList(data));
  // }, [dispatch]);


  const handleMedsSelect = (selectedOption) => {
    if (selectedOption) {
      const medicine = options.find((item) => item.value === selectedOption.value);
      if (medicine) {
        setMedsArray((prev) => [
          ...prev,
          { meds: medicine.Medication, quantity: 0 }, // Default quantity is 1
        ]);
      }
    }
  };


  const handleQuantityChange = (index, value) => {
    const updatedMeds = [...medsArray];
    updatedMeds[index].quantity = value;
    setMedsArray(updatedMeds);
    calculateGrandTotal(updatedMeds);
  };

  const calculateGrandTotal = (meds) => {
    const total = meds.reduce((sum, med) => sum + med.quantity, 0);
    setGrandTotal(total);
  };

  const fetchMedicines = async (searchTerm) => {
    if (searchTerm.length < 3) {
      setOptions([]);
      return;
    }

    const apiData = { name: searchTerm };
    setIsLoading(true);

    try {

      const responsee = await fetch(
        `${process.env.REACT_APP_HOST_NAME}api/clynxx/getMedicines`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(apiData),
        }
      );

      const response = await responsee.json();

      const formattedOptions = response?.data?.medicines?.map((med) => ({
        label: `${med?.name} | Size: ${med?.size}`,
        value: med?.id,
      })) || [];

      setOptions(formattedOptions);
    } catch (error) {
      console.error("Failed to fetch medicines:", error);
      setOptions([]);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedFetch = debounce(fetchMedicines, 500);

  useEffect(() => {
    debouncedFetch(inputValue);
    return () => debouncedFetch.cancel();
  }, [inputValue]);


  const handleSendCode = async () => {
    try {
      const payload = {
        email: formData.email,
        method: formData.method,
      };

      const response = await fetch(`${process.env.REACT_APP_HOST_NAME}api/clynxx/sendCode`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });

      const result = await response.json();

      if (response?.ok && result?.data?.response_code === 1) {
        toast.success(result.data?.messages);
        setIsFirstModalOpen(false);
        setIsSecondModalOpen(true);
      } else {
        toast.error(result.data?.messages || "Failed to send code");
      }
    } catch (error) {
      console.error("Error sending code:", error);
      toast.error("An error occurred while sending the code.");
    }
  };

  const handleVerifyCode = async () => {
    try {
      setIsLoading(true);
      const payload = {
        email: formData.email,
        code: parseInt(code),
      };

      const response = await fetch(`${process.env.REACT_APP_HOST_NAME}api/clynxx/challengeCode`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
      });
      const result = await response.json();
      if (response.ok && result?.data?.response_code === 1) {
        toast.success(result?.data?.messages);
        const token = result?.data?.data?.token;

        const { method, ...restFormData } = formData;
        const updatedFormData = {
          ...restFormData,
          token: token,
        };

        const createPrescriptionResponse = await fetch(
          `${process.env.REACT_APP_HOST_NAME}api/clynxx/createPrescription`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(updatedFormData),
          }
        );

        const createPrescriptionResult = await createPrescriptionResponse.json();
        if (createPrescriptionResponse.ok && createPrescriptionResult?.data?.response_code === 1) {
          dispatch(createPrescription(prescriptionData));

          setIsSecondModalOpen(false);
          navigate("/prescription")
          toast.success("Prescription created successfully!");
        } else {
          toast.error(createPrescriptionResult?.data?.messages || "Failed to create prescription");
        }
      } else {
        toast.error(result?.data?.messages || "Verification failed");
      }
    } catch (error) {
      console.error("Error verifying code:", error);
      toast.error("An error occurred while verifying the code.");
    } finally {
      // dispatch(startstopLoading(false));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // let data = {
    //   page: 1,
    //   limit: 10,
    // };
    dispatch(getClientListNewForClynxx());
  }, []);


  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#1b95bc' : '#1b95bc',
      borderRadius: '4px',
      boxShadow: state.isFocused ? '0 0 0 1px #1b95bc' : provided.boxShadow,
      '&:hover': {
        borderColor: state.isFocused ? '#1b95bc' : '#1b95bc',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '14px',
      fontStyle: 'italic',
      color: '#aaa',
    }),
  };

  const handleInputChange = (value) => {
    setInputValue(value);
    if (value.length > 0) {
      setHasTyped(true);
    } else {
      setHasTyped(false);
    }
  };

  return (
    <Main>
      <div className="container-fluid py-3 h-100">
        <div className="row py-2">
          <div className="col d-flex justify-content-between align-items-center">
            <div className="title">
              <h2 className="booking-h2">New Prescriptions</h2>
            </div>
            {id !== "add" && (
              <div className="px-3 py-2">
                <form className="bg-white px-3 py-2 search-outer">
                  <div className="search-div position-relative text-end">
                    <input
                      className="search-img ms-3 position-absolute top-50 translate-middle"
                      type="image"
                      src={searchImg}
                      alt="pic not found"
                    />
                    <input
                      className="search py-2"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>

        <div className="row p-0">
          <div className="col-lg-7 ps-0">
            <div className="create-form bg-white">
              <div className="create-title">
                <h6 className="common_title">Create New Prescription</h6>
              </div>

              <Formik
                initialValues={{
                  date: new Date().toISOString().split("T")[0],
                  // diagnosis: "",
                  medicines: [{ meds: "", quantity: 0, dosage: "" }],
                  customFees: [],
                  patient_id: ""
                }}
                // validate={(values) => {
                //   const errors = {};
                //   if (!values.date) errors.date = "Required";
                //   if (!values.diagnosis) errors.diagnosis = "Required";
                //   return errors;
                // }}
                onSubmit={(values) => {

                  const isValid = values?.patient_id?.value && values?.medicines?.length > 0;

                  if (isValid) {
                    const formattedData = {
                      email: formData?.email,
                      // email: "adam@toothaid.co",
                      token: "",
                      // chamber_token: "knhUYvR40FpgcI5S2xlX",
                      chamber_token: profileDetails?.dentistClynxxChamber_token,
                      patient_id: Number(values?.patient_id?.value),
                      medicines: JSON.stringify(
                        values.medicines.map(med => ({
                          medicine_id: Number(med.meds),
                          dosage: med.dosage,
                          qty: Number(med.quantity)
                        }))
                      ),
                      is_paid: 1,
                      rx_transmission: 2,
                      // diagnosis: values.diagnosis,
                      ...(values.customFees.length > 0
                        ? {
                          custom_fee: values.customFees
                            .filter(fee => fee.name && fee.price)
                            .map(fee => ({
                              name: fee.name,
                              fee: Number(fee.price),
                            })),
                        }
                        : {}),
                    };
                    setFormData({ ...formattedData });
                    if (values.date && values.medicines.length > 0) {

                      const createPrescriptionForListing = {
                        date: values.date,
                        //  notes: values.diagnosis,
                        id: values?.patient_id?.id,
                        medication: values.medicines.map(med => ({
                          meds: Number(med.meds),
                          quantity: Number(med.quantity),
                          dosage: med.dosage,
                          name: medicineName.label
                        }))
                      }
                      setPrescriptionData({ ...createPrescriptionForListing })
                      // dispatch(createPrescription(createPrescriptionForListing))
                    }
                    setIsFirstModalOpen(true);

                  } else {
                    toast.error("Please fill all required fields.");
                  }
                }}
              >
                {({ values, errors, handleChange, setFieldValue }) => (
                  <Form>
                    <div className="row px-3 py-4">
                      <div className="col-md-12 px-4">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group mb-3 input-shadow">
                              <label className="view-label-input">Date</label>
                              <Field
                                type="date"
                                name="date"
                                placeholder="dd/mm/yyyy"
                                className="view-label-input form-control select_input"
                                min={new Date().toISOString().split("T")[0]}
                                value={new Date().toISOString().split("T")[0]}
                                disabled
                              />
                              {/* {errors.date && <p className="formik-errors">{errors.date}</p>} */}
                            </div>
                          </div>


                          {/* {id === "add" && (
                            <div className="col-12">
                              <div className="form-group mb-3">
                                <label className="view-label-input">Client</label>
                                <select
                                  name="patient_id"
                                  onChange={(e) => {
                                    setFieldValue("patient_id", e.target.value);
                                  }}
                                  className="view-label-input form-control select_input"
                                >
                                  <option>Select</option>
                                  {data?.map((item) => {
                                    return (
                                      <>
                                        <option value={item?.user_id?.patient_id}>
                                          {item?.user_id?.firstName + " " + item?.user_id?.lastName}
                                        </option>
                                      </>
                                    );
                                  })}
                                </select>
                              </div>
                            </div>
                          )} */}


                          {id === "add" && (
                            <div className="col-12">
                              <div className="form-group mb-3">
                                <label className="view-label-input">Client</label>
                                <Select
                                  name="patient_id"
                                  styles={{
                                    ...customStyles,
                                    placeholder: (provided) => ({
                                      ...provided,
                                      fontSize: '12px',
                                      color: '#000',
                                    }),
                                  }}
                                  options={data?.map((item) => ({
                                    label: `${item?.user_id?.firstName} ${item?.user_id?.lastName}`,
                                    value: item?.user_id?.patient_id,
                                    id: item?.user_id?._id
                                  }))}
                                  onChange={(selectedOption) => setFieldValue("patient_id", selectedOption)}
                                  placeholder="Select Client"
                                  classNamePrefix="react-select"
                                  isClearable
                                  isSearchable
                                />
                              </div>
                            </div>
                          )}


                          <div className="form-group mb-3">
                            <FieldArray
                              name="medicines"
                              render={({ push, remove }) => (
                                <div>
                                  {values.medicines.map((med, index) => (
                                    <>
                                      <div key={index} className="col-md-12 d-flex align-items-center gap-3 mb-2">
                                        <div className="col-md-8">
                                          <label className="view-label-input">Medication</label>
                                          {/* <Select
                                          name={`medicines[${index}].meds`}
                                          options={options}
                                          onChange={(selectedOption) => {
                                            setFieldValue(`medicines[${index}].meds`, selectedOption?.value);
                                          }}
                                          isClearable
                                          onInputChange={(value) => setInputValue(value)}
                                          isSearchable
                                          styles={customStyles}
                                          placeholder={isFocused ? "" : <h6>Medicine Name</h6>}
                                          onFocus={() => setIsFocused(true)}
                                          onBlur={() => setIsFocused(false)}
                                          noOptionsMessage={() => (isLoading ? "Loading..." : <p>Please enter 2 or more characters</p>)}
                                        /> */}

                                          <Select
                                            name={`medicines[${index}].meds`}
                                            options={options}
                                            onChange={(selectedOption) => {
                                              setFieldValue(`medicines[${index}].meds`, selectedOption?.value);
                                              setMedicineName(selectedOption)
                                            }}
                                            isClearable
                                            onInputChange={handleInputChange}
                                            isSearchable
                                            styles={{
                                              ...customStyles,
                                              placeholder: (provided) => ({
                                                ...provided,
                                                fontSize: '12px',

                                                color: '#000',
                                              }),
                                            }}
                                            placeholder={isFocused ? "" : "Medicine Name"}
                                            onFocus={() => setIsFocused(true)}
                                            onBlur={() => setIsFocused(false)}
                                            noOptionsMessage={() => {
                                              if (isLoading) {
                                                return "Loading...";
                                              }
                                              if (!hasTyped) {
                                                return <p className="label-text">Please enter 3 or more characters</p>;
                                              }
                                              return "No medicines found";
                                            }}
                                          />
                                        </div>

                                        <div className="col-md-4 mb-2">
                                          <label className="view-label-input hint-text"><span>Qty</span>
                                            <span className="circle-bullet">•</span> Enter the exact amount required e.g 28 tablets not 1 pack</label>
                                          <input
                                            type="number"
                                            className="form-control select_input"
                                            value={med.quantity}
                                            onChange={(e) => {
                                              setFieldValue(`medicines[${index}].quantity`, e.target.value);
                                            }}
                                          />
                                        </div>

                                      </div>

                                      <div key={index} className="col-md-12 d-flex align-items-end mb-3 gap-3 input-shadow">

                                        {/* <div className="col-md-8 dosege-input input-shadow">
                                          <label className="view-label-input">Dosage Instruction</label>
                                          <Field
                                            as="textarea"
                                            rows={5}
                                            name="diagnosis"
                                            className="form-control select_input"
                                            value={med.dosage}
                                            onChange={(e) => {
                                              setFieldValue(`medicines[${index}].dosage`, e.target.value);
                                            }}
                                          />
                                        </div> */}

                                        <div className="col-md-10">
                                          <div className="form-group mb-3 mt-2 prescripion_notes new-prescription">
                                            <label className="view-label-input">Add Instructions / Dose Field</label>
                                            <Field
                                              as="textarea"
                                              rows={5}
                                              className="form-control select_input"
                                              placeholder="Type here..."
                                              value={med.dosage}
                                              onChange={(e) => {
                                                setFieldValue(`medicines[${index}].dosage`, e.target.value);
                                              }}
                                            />
                                          </div>
                                        </div>

                                        {index > 0 && <div className="col-md-2 mb-3">
                                          <button
                                            type="button"
                                            className="btn btn-danger"
                                            onClick={() => remove(index)}
                                          >
                                            X
                                          </button>
                                        </div>}
                                      </div>
                                    </>

                                  ))}

                                  {/* Add Medication Button */}
                                  <div className="row add-medication-btn mb-3">
                                    <div className="col-md-12">
                                      <button
                                        type="button"
                                        className="btn cre_new"
                                        onClick={() => push({ meds: "", quantity: 0, dosage: "" })}
                                      >
                                        + Add Medication
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            />

                            <FieldArray
                              name="customFees"
                              render={({ push, remove }) => (
                                <div>

                                  {values.customFees.map((fee, index) => (
                                    <React.Fragment key={index}>
                                      <div className="col-md-12 d-flex align-items-end gap-3 mb-3 input-shadow">
                                        <div className="col-md-7">
                                          <label className="view-label-input">Custom Fee Detail</label>
                                          <input
                                            type="text"
                                            className="form-control select_input"
                                            value={fee.name}
                                            onChange={(e) => {
                                              setFieldValue(`customFees[${index}].name`, e.target.value);
                                            }}
                                          />
                                        </div>
                                        <div className="col-md-3 ">
                                          <label className="view-label-input">Price</label>
                                          <input
                                            type="number"
                                            className="form-control select_input"
                                            value={fee.price}
                                            onChange={(e) => {
                                              setFieldValue(`customFees[${index}].price`, e.target.value);
                                            }}
                                          />
                                        </div>
                                        {index >= 0 && (
                                          <div className="col-md-2">
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() => remove(index)}
                                            >
                                              X
                                            </button>
                                          </div>
                                        )}
                                      </div>
                                    </React.Fragment>
                                  ))}

                                  <div className="row add-medication-btn mb-3">
                                    <div className="col-md-12">
                                      <button
                                        type="button"
                                        className="btn cre_new"
                                        onClick={() => push({ name: "", price: 0 })}
                                      >
                                        + Add Custom Fee
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            />
                          </div>

                          {/* <div className="form-group mb-3 mt-2 prescripion_notes">
                            <label className="view-label-input">Grand Total</label>
                            <input
                              type="text"
                              placeholder="Grand Total"
                              className="view-label-input form-control select_input"
                              value={2}
                              disabled
                            />
                          </div> */}

                          {/* <div className="col-md-12">
                            <div className="form-group mb-3 mt-4 prescripion_notes new-prescription">
                              <label className="view-label-input">Add Instructions / Dose Field</label>
                              <Field
                                as="textarea"
                                rows={5}
                                name="diagnosis"
                                className="form-control select_input"
                                placeholder="Type here..."
                              />
                              {errors.diagnosis && <p className="formik-errors">{errors.diagnosis}</p>}
                            </div>
                          </div>  */}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 px-4">
                        <div className="create_new_btn p-2 pb-4">
                          <button type="submit" className="btn cre_new">
                            Create
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          {id !== "add" && (
            <div className="col-lg-5 col-12 pe-0">
              <div className="bg-white table_scroll scrollbar-hide ad-card-prop">
                {showModal && (
                  <ViewPrescription
                    handleClose={() => setShowModal(false)}
                    viewPresData={viewP}
                    handlePrint={handlePresPrint}
                  />
                )}
                <div className="create-title">
                  <h6 className="common_title">Prescriptions</h6>
                </div>
                <div>
                  <Table>
                    <Table className="all_consultations" responsive>
                      <thead className="mb-2 table_head">
                        <tr>
                          <th className="w-25">Issue Date</th>
                          <th className="td_width_item">Item</th>
                          <th>Quantity</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="table_body">
                        {prescriptions?.data?.map((res, index) => (
                          <tr key={index}>
                            <div className="table-rows d-flex gap-4">
                              <td>{formatDateToDMY(res?.date)}</td>
                              <td className="td_width_item">
                                {res.medication.map((medsRes, idx) => (
                                  <p key={idx}>{medsRes?.meds}</p>
                                ))}
                              </td>
                              <td className="td_width_quantity">
                                {res.medication.map((qnty, idx) => (
                                  <p key={idx}>{qnty?.quantity}</p>
                                ))}
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    setShowModal(true);
                                    setViewP(res);
                                  }}
                                  className="table_link_ text-decoration-underline text-color border-0 bg-transparent"
                                >
                                  View
                                </button>
                              </td>
                            </div>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* First Modal: Send Code */}
      <div
        className={`modal fade ${isFirstModalOpen ? "show d-block" : ""}`}
        tabIndex="-1"
        style={{ backgroundColor: isFirstModalOpen ? "rgba(0, 0, 0, 0.5)" : "" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Send Code</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setIsFirstModalOpen(false)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="Enter email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="method" className="form-label">
                  Method
                </label>
                <select
                  id="method"
                  className="form-select"
                  value={formData.method}
                  onChange={(e) => setFormData({ ...formData, method: parseInt(e.target.value) })}
                  required
                >
                  <option value="0">Email & SMS</option>
                  <option value="1">SMS</option>
                  <option value="2">Email</option>
                </select>
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn cre_new" onClick={handleSendCode}>
                Send Code
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setIsFirstModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Second Modal: Verify Code */}
      <div
        className={`modal fade ${isSecondModalOpen ? "show d-block" : ""}`}
        tabIndex="-1"
        style={{ backgroundColor: isSecondModalOpen ? "rgba(0, 0, 0, 0.5)" : "" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Verify Code</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => setIsSecondModalOpen(false)}
              ></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="code" className="form-label">
                  Code
                </label>
                <input
                  type="text"
                  id="code"
                  className="form-control"
                  placeholder="Enter the code"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn cre_new" onClick={handleVerifyCode}>
                {isLoading ? (
                  <span>
                    <span className="spinner" />
                    Verifying...
                  </span>
                ) : (
                  "Verify Code"
                )}
              </button>
              {/* <button
                className="btn btn-secondary"
                onClick={() => setIsSecondModalOpen(false)}
              >
                Cancel
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
}
