import React, { useState, useRef, useEffect } from "react";
import Peer from "peerjs";
import { toastInfo } from "../../Redux/Reducers/admin/adminPanelSlice";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import MomentFunc from "../../Utils/momentDateTime";

const VideoCall = ({ consInfo, socket, onlineUsers, modalFunc }) => {
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState(null);
  const [call, setCall] = useState({});
  const [userId, setUserId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [me, setMe] = useState("");
  const [timeLeft, setTimeLeft] = useState(0); // Time left in seconds
  const [isTimerActive, setIsTimerActive] = useState(false);

  const myVideo = useRef(null);
  const userVideo = useRef(null);
  const peerInstance = useRef(null);

  // Initialize PeerJS and setup event listeners
  useEffect(() => {
    const peer = new Peer(undefined, {
      host: process.env.REACT_APP_VIDEOCALL_URL,
      // port: 4055,
      path: "/peerjs",
      secure: true,
    });

    peer?.on("open", (id) => {
      console.log("PeerJS connection opened with ID:", id);
      setMe(id);
    });

    peer?.on("call", (incomingCall) => {
      incomingCall?.answer(stream);

      incomingCall?.on("stream", (currentStream) => {
        if (userVideo.current) {
          userVideo.current.srcObject = currentStream;
        }
      });

      incomingCall?.on("close", () => {
        console.log("Call ended.");
        setCallEnded(true);
      });
    });

    peer?.on("error", (err) => {
      console.error("PeerJS error:", err);
      // toast.error(`Error: ${err.message}`);
    });

    peerInstance.current = peer;

    return () => {
      peer.destroy();
      stream?.getTracks().forEach((track) => track.stop());
    };
  }, [stream]);

  // Fetch media stream
  useEffect(() => {
    const getMediaStream = async () => {
      await navigator?.mediaDevices?.getUserMedia({ video: true, audio: true })
        .then((currentStream) => {
          setStream(currentStream);
          if (myVideo.current) {
            myVideo.current.srcObject = currentStream;
            myVideo.current.play();
          }
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            console.error("Media stream aborted:", err);
            toast.error("Unable to access your camera or microphone.");
          } else {
            console.error("Media stream error:", err);
          }
        });
    };

    getMediaStream();

  }, []);

  // Set User ID based on online users
  useEffect(() => {
    if (consInfo && onlineUsers) {
      const socketId = onlineUsers?.find(
        (item) => item.userId === consInfo?.user_id?._id
      )?.socketId;
      setUserId(socketId || null);
    }
  }, [consInfo, onlineUsers]);

  useEffect(() => {
    socket.on("ice-candidate", ({ candidate }) => {
      if (candidate) {
        peerInstance?.current?.signal(candidate);
      }
    });

    return () => socket.off("ice-candidate");
  }, [socket]);

  // Call user
  const callUser = async () => {
    if (!userId) {
      console.error("User ID is missing");
      return;
    }

    socket.emit("callUser", {
      userToCall: userId,
      from: me,
      name: `${consInfo?.assignedDoctor?.firstName} ${consInfo?.assignedDoctor?.lastName}`,
    });

    const outgoingCall = peerInstance?.current?.call(userId, stream);

    outgoingCall?.on("stream", (currentStream) => {
      if (userVideo.current) {
        userVideo.current.srcObject = currentStream;
        userVideo?.current?.play();
      }
    });

    outgoingCall?.on("close", () => {
      setCallEnded(true);
    });

    outgoingCall?.on("ice-candidate", (candidate) => {
      socket.emit("ice-candidate", { candidate, to: userId });
    });

    setCall(outgoingCall);
    setCallAccepted(true);
  };

  // Leave call
  const leaveCall = () => {
    socket.emit("doctor-disconnected", {
      to: userId,
      consultationId: consInfo?._id,
    });

    fetch(`${process.env.REACT_APP_HOST_NAME}api/pay/complete`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ consultationId: consInfo?._id }),
    })
      .then((response) => response.json())
      .then((data) => console.log("Payment complete:", data))
      .catch((error) => console.error("Error completing payment:", error));

    peerInstance.current?.destroy();
    stream?.getTracks().forEach((track) => track.stop());
    setCallAccepted(false);
    setCallEnded(true);
    window.location.reload();
  };

  // Confirm Leave Call
  const handleLeaveCallClick = () => setShowModal(true);
  const confirmLeaveCall = () => {
    setShowModal(false);
    leaveCall();
  };
  const cancelLeaveCall = () => setShowModal(false);

  ////////////////////============================================================================================////////////////////////////////

  useEffect(() => {
    if (!consInfo?.date) return;

    const todayDate = new Date().toISOString().split("T")[0]; // "2025-02-20"
    const fullDateTimeString = `${todayDate} ${MomentFunc.Time(consInfo?.date)}`; 

    const scheduledTime = new Date(fullDateTimeString).getTime();
    const currentTime = new Date().getTime();
    const countdownStartTime = scheduledTime - 15 * 60 * 1000; 

    const timeDiff = Math.floor((scheduledTime - currentTime) / 1000); 

    if (currentTime >= countdownStartTime && timeDiff > 0) {
      setTimeLeft(timeDiff);
      setIsTimerActive(true);
    } else {

      setTimeLeft(0);
      setIsTimerActive(false);
    }
  }, [consInfo]);



  useEffect(() => {
    let timerId;

    if (isTimerActive) {
      timerId = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1; 
          } else {
            clearInterval(timerId);
            setIsTimerActive(false);
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [isTimerActive]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
      <div className="d-flex justify-content-end mb-3">
        <button
          className="btn btn-primary white-btn small_white_btn mt-0"
          onClick={handleLeaveCallClick}
          disabled={!callAccepted}
        >
          Leave Call
        </button>
      </div>

      <div className="card mb-4 video_call_section">

        {timeLeft > 0 && (
          <div className="timer">
            <p>Your session will begin in:</p>
            <span>{formatTime(timeLeft)}</span>
          </div>
        )}

        <div className="pb-0">
          <div className="consulatation_card">
            {callAccepted && !callEnded ? (
              <>
                <video
                  playsInline
                  ref={userVideo}
                  autoPlay
                  className="full-video"
                />

              </>

            ) : (
              <img
                src={require("../../Assets/Images/lg_video_frame.png")}
                alt="live_chat"
                className="img-fluid large_img"
              />
            )}
            <img
              src={require("../../Assets/Images/small_video_frame.png")}
              alt="live_chat"
              className="img-fluid video_call_frame"
            />
            <video
              playsInline
              muted
              ref={myVideo}
              autoPlay
              className="doctor-video"
            />
            {userId && !callAccepted && timeLeft === 0 && (
              <div className="start_call">
                <button onClick={() => callUser(userId)} className="cre_new">
                  Start Call
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={cancelLeaveCall}>
        <Modal.Header className="justify-content-center border-shadow">
          <Modal.Title className="text-center">Confirm Leave Call</Modal.Title>
        </Modal.Header>
        <Modal.Body className="border-shadow">
          <div className="text-center leave-call-txt">
            Are you sure you want to cancel the call?
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="d-flex justify-content-center gap-2">
            <Button variant="secondary" onClick={cancelLeaveCall}>
              Cancel
            </Button>
            <Button variant="danger" onClick={confirmLeaveCall}>
              Leave Call
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VideoCall;
