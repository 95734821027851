import Main from '../Main/Main'
// import './clients.css'
import searchImg from '../../Assets/Icons/search.svg'
import { Table } from 'react-bootstrap'
import { Link, useLocation, useParams } from 'react-router-dom'
import profile from '../../Assets/Images/defaultUser.jpg'
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getClientList } from '../../Redux/Actions/user/userAll'
import { formatDateToDMY } from '../../Utils/commonFiles'
import { startstopLoading } from '../../Redux/Reducers/globalSlice'


export default function Clients() {

  const limit = 10;
  const location = useLocation()
  const Image_url = process.env.REACT_APP_IMG_URL + "public/uploads/";
  const dispatch = useDispatch();
  const query = useParams()
  const [page, setPage] = useState("1");
  const [search, setSearch] = useState("");
  const filter = new URLSearchParams(location.search);
  let dateValue = filter.get('date') || '';

  console.log('query', location.search)
  const [date, setDate] = useState();
  const [searchDate, setSearchDate] = useState(dateValue);

  const data = useSelector((state) => state.consultaions.clientList);

  useEffect(() => {
    let data = {
      page,
      limit,
      search,
    };
    if (searchDate) {
      data['date'] = searchDate;
    }
    dispatch(startstopLoading(true));
    dispatch(getClientList(data)).then((res) => {
      if (res?.payload?.success == true || res?.payload?.success == false) {
        dispatch(startstopLoading(false));
      }
    });
  }, [searchDate, page, limit, search]);

  const handlePageClick = (e) => {
    setPage(e?.selected + 1);
  };

  const handlefilter = (type) => {
    const filter = new URLSearchParams(location.search);
    if (type === 'Add') {
      filter.set('date', date);
      window.history.pushState({}, '', `${location.pathname}?${filter.toString()}`);
      setSearchDate(date);
    } else {
      filter.delete('date');
      window.history.pushState({}, '', `${location.pathname}?${filter.toString()}`);
      setSearchDate('');
    }
  };

  return (
    <Main>
      <div className="containe-fluid px-3 ">
        <div className="row">
          <div className="col-lg-11 bg-white p-0 client ad-card-prop">
            <div className="d-flex flex-row justify-content-between align-items-center p-3">
              <div className=" client-heading-div">
                <h2 className='common-properties-clients m-0'>My Clients</h2>
              </div>
              <div className=" ">
                <form className="" role="search">
                  <div className='search-div position-relative text-end'>
                    <input className='search-img ms-4 position-absolute top-50 translate-middle ' type="image" src={searchImg} alt="pic not found" />
                    <input className=" search w-75 py-2 search_padding " type="search" onChange={(e) => setSearch(e.target.value)} placeholder="Search" aria-label="Search" />
                  </div>
                </form>
              </div>
            </div>
            <div className="main-content-client ">
              <Table className=' table_noscroll'  >
                <thead className=' '>
                  <tr className=''>
                    <th></th>
                    <th>Name</th>
                    <th className=''>
                      <div className='d-flex align-items-end'>
                        Joining Date
                        <div className="dropdown position-relative ">
                          <button className="btn py-0 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">

                          </button>
                          <ul className="dropdown-menu dropdown-menu-end py-5 px-4 mt-3 position-absolute" aria-labelledby="dropdownMenuButton1">
                            <li className=''>
                              <input type="date" onChange={(e) => setDate(e.target.value)} value={date || new Date(searchDate)} defaultValue={new Date(searchDate)} className='dropdown-item select_input px-5' name="" id="" />
                            </li>
                            <li className='text-center pt-3 d-flex justify-content-between gap-2 '>
                              <button className='cre_new w-75 m-0' onClick={() => handlefilter('Add')} type="submit">Add</button>
                              <button className='cre_new w-75 m-0' onClick={() => handlefilter('Rmv')} type="submit">Remove</button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </th>
                    <th>Email Address</th>
                    <th>Past Consultations</th>
                    <th>Upcoming Consultations</th>
                  </tr>
                </thead>
                {data?.list.length ?
                  <tbody>
                    {data?.list?.map((client, index) => (
                      <tr key={index} className='table-row'>
                        <td>
                          <div className='set-pro-table'>
                            <img src={client.image ? (Image_url + client.image) : profile} alt="Pic not found!" className='list-image' />
                          </div>
                        </td>
                        <td className='text-capitalize'> <Link to={`/client/${client?._id}`} className='no-underline text-black'>{client?.firstName + ' ' + client?.lastName}</Link> </td>
                        <td>{formatDateToDMY(client?.createdAt)}</td>
                        <td>{client.email}</td>
                        <td>{client?.pastConsultations}</td>
                        <td>{client?.upcomingConsultations}</td>
                      </tr>

                    ))}
                  </tbody>
                  :
                  <>
                    <caption className="text-center">
                      No Data
                    </caption>
                  </>
                }
              </Table>
              {data?.total > 10 && <div className="pagination_new d-flex justify-content-center">
                <div className="justify-content-center pagination_inner mt-1">
                {/* <ReactPaginate
                    className="pagination"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={data?.total / limit}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  /> */}
                  </div>
                {/* <ReactPaginate
                  activeClassName="active"
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  pageCount={data?.total / limit}
                  // pageCount={100 / limit}
                  previousLabel="Prev"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                /> */}
              </div>}
            </div>
          </div>
        </div>
      </div>

    </Main>
  )
}