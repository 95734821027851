import profile from "../../Assets/Images/defaultUser.jpg";
import Main from "../Main/Main";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  addReview,
  getDashboardHistory,
  setDentistAvailability,
} from "../../Redux/Actions/user/userAll";
import ReactStars from "react-stars";
import { getImageUrl, options } from "../../Utils/commonFiles";
import { startstopLoading } from "../../Redux/Reducers/globalSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastInfo } from "../../Redux/Reducers/admin/adminPanelSlice";

// 2 available 1 not available ==> for Availability
// 1 denstist on holiday 2 dentist is not holiday
export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const profileDetails = useSelector((state) => state.consultaions.profilePic);
  const data = useSelector((state) => state.consultaions.dashboardData);

  const dayMap = {
    Mon: "Monday",
    Tue: "Tuesday",
    Wed: "Wednesday",
    Thu: "Thursday",
    Fri: "Friday",
    Sat: "Saturday",
    Sun: "Sunday"
  };

  const [isHolidayMode, setIsHolidayMode] = useState(false);
  const [availability, setAvailability] = useState({
    Mon: [{ start: "12:00 am", end: "12:00 am" }],
    Tue: [{ start: "12:00 am", end: "12:00 am" }],
    Wed: [{ start: "12:00 am", end: "12:00 am" }],
    Thu: [{ start: "12:00 am", end: "12:00 am" }],
    Fri: [{ start: "12:00 am", end: "12:00 am" }],
    Sat: [{ start: "12:00 am", end: "12:00 am" }],
    Sun: [{ start: "12:00 am", end: "12:00 am" }],
  });
  const [availabilityStatus, setAvailabilityStatus] = useState({
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });

  const [state, setState] = useState({
    rating: 5,
    comment: "",
  });

  const formatTo12Hour = (time24) => {
    const [hours, minutes] = time24.split(':');
    const hourNum = parseInt(hours);
    const period = hourNum >= 12 ? 'pm' : 'am';
    const hour12 = hourNum % 12 === 0 ? 12 : hourNum % 12; 
    return `${String(hour12).padStart(2, "0")}:${minutes} ${period}`;
  };

  const ratingChanged = (newRating) => {
    setState({
      ...state,
      rating: newRating,
    });
  };

  const submitReview = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addReview(state)).then(() => {
        let button = document.getElementById("close-modal");
        button.click();
      });
    } catch (err) { }
  };

  useEffect(() => {
    dispatch(startstopLoading(true));
    dispatch(getDashboardHistory()).then((res) => {
      if (res?.payload?.success === true || res?.payload?.success === false) {
        dispatch(startstopLoading(false));
      }
    });
  }, [dispatch]);

  useEffect(() => {
    if (profileDetails?.dentistAvailability) {
      const updatedAvailability = {
        Mon: [{ start: "12:00 am", end: "12:00 am" }],
        Tue: [{ start: "12:00 am", end: "12:00 am" }],
        Wed: [{ start: "12:00 am", end: "12:00 am" }],
        Thu: [{ start: "12:00 am", end: "12:00 am" }],
        Fri: [{ start: "12:00 am", end: "12:00 am" }],
        Sat: [{ start: "12:00 am", end: "12:00 am" }],
        Sun: [{ start: "12:00 am", end: "12:00 am" }],
      };
      const updatedStatus = {
        Mon: false,
        Tue: false,
        Wed: false,
        Thu: false,
        Fri: false,
        Sat: false,
        Sun: false,
      };

      profileDetails.dentistAvailability.forEach((dayData) => {
        const shortDay = Object.keys(dayMap).find(key => dayMap[key] === dayData.day);
        if (shortDay) {
          updatedAvailability[shortDay] = dayData.timings.map((timing) => ({
            start: timing.from === "00:00" ? "12:00 am" : (timing.from === "23:59" ? "11:59 pm" : timing.from),
            end: timing.to === "00:00" ? "12:00 am" : (timing.to === "23:59" ? "11:59 pm" : timing.to),
          }));
          updatedStatus[shortDay] = dayData.isAvailable === 1;
        }
      });

      setAvailability(updatedAvailability);
      setAvailabilityStatus(updatedStatus);

      if (profileDetails?.isOnHoliday) {
        setIsHolidayMode(profileDetails.isOnHoliday === 1);
      }
    }
  }, [profileDetails]);

  const handleTimeChange = (day, index, type, value) => {
    if (!isHolidayMode) {
      const updatedAvailability = { ...availability };
      updatedAvailability[day][index][type] = value;
      setAvailability(updatedAvailability);
    }
  };

  const handleAvailabilityChange = (day) => {
    if (!isHolidayMode) {
      setAvailabilityStatus((prev) => {
        const newStatus = { ...prev, [day]: !prev[day] };
        if (newStatus[day]) {
          const updatedAvailability = { ...availability };
          updatedAvailability[day] = [{ start: "12:00 am", end: "12:00 am" }];
          setAvailability(updatedAvailability);
        }
        return newStatus;
      });
    }
  };

  const addTimeSlot = (day) => {
    if (!isHolidayMode) {
      const updatedAvailability = { ...availability };
      updatedAvailability[day].push({ start: "12:00 am", end: "12:00 am" });
      setAvailability(updatedAvailability);
    }
  };

  const removeTimeSlot = (day, index) => {
    if (!isHolidayMode) {
      const updatedAvailability = { ...availability };
      updatedAvailability[day].splice(index, 1);
      setAvailability(updatedAvailability);
    }
  };

  // const generateTimeOptions = () => {
  //   const times = [];
  //   for (let hour = 0; hour < 24; hour++) {
  //     const time24 = `${String(hour).padStart(2, "0")}:00`;
  //     times.push(formatTo12Hour(time24));
  //   }
  //   times.push("11:59 pm");
  //   return times;
  // };

  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 7; hour <= 23; hour++) { // Start at 8 AM, end at 11 PM
      const time24 = `${String(hour).padStart(2, "0")}:00`;
      times.push(formatTo12Hour(time24));
    }
    return times;
  };

  const generateAvailabilityJSON = () => {
    const dentistAvailability = Object.keys(availability).map((day) => ({
      day: dayMap[day],
      timings: availability[day].map((slot) => ({
        from: slot.start,
        to: slot.end,
      })),
      isAvailable: availabilityStatus[day] ? 1 : 2,
    }));

    return {
      isOnHoliday: isHolidayMode ? 1 : 2,
      dentistAvailability,
    };
  };

  const hasChanges = () => {
    const currentJSON = generateAvailabilityJSON();
    const originalAvailability = profileDetails?.dentistAvailability || [];
    const originalIsOnHoliday = profileDetails?.isOnHoliday || 2;

    // Check if holiday mode changed
    if (currentJSON.isOnHoliday !== originalIsOnHoliday) {
      return true;
    }

    // If no original availability, check if current has any changes from default
    if (!originalAvailability.length) {
      return Object.keys(availability).some(day => {
        const slots = availability[day];
        const status = availabilityStatus[day];
        return status || // If available is true
          slots.length > 1 || // If more than one time slot
          slots[0].start !== "00:00 am" || // If start time changed
          slots[0].end !== "00:00 pm"; // If end time changed
      });
    }

    // Compare each day's data
    for (const currentDay of currentJSON.dentistAvailability) {
      const originalDay = originalAvailability.find(d => d.day === currentDay.day);

      // If day not found in original, it's a change
      if (!originalDay) return true;

      // Check availability status
      if (currentDay.isAvailable !== originalDay.isAvailable) {
        return true;
      }

      // Check number of time slots
      if (currentDay.timings.length !== originalDay.timings.length) {
        return true;
      }

      // Compare each time slot
      for (let i = 0; i < currentDay.timings.length; i++) {
        const currentTiming = currentDay.timings[i];
        const originalTiming = originalDay.timings[i];

        // Direct string comparison of times
        if (currentTiming.from !== originalTiming.from ||
          currentTiming.to !== originalTiming.to) {
          return true;
        }
      }
    }

    // Check if any days were removed
    if (originalAvailability.length > currentJSON.dentistAvailability.length) {
      return true;
    }

    return false;
  };

  const handleDentistAvailability = () => {
    if (hasChanges()) {
      dispatch(setDentistAvailability(generateAvailabilityJSON())).then((res) => {
        if (res?.payload?.success) {
          toast.success(res?.payload?.data);
        } else {
          toast.error(res?.payload?.data);
        }
      });
    }
  };
  return (
    <Main>
      <div className="dashboard-container container-fluid p-0">
        <div className="dashboard-col">
          <div className="dashboard-row  col-xxl-11 col-xl-12 col-12">
            <div className="row">
              <div className="col-md-4">
                <div className="details-div same-property ">
                  <div className="heading-div p-sm-0">
                    <h2 className="details-h2 common-h2">My Details</h2>
                  </div>
                  <div className="detail-dontent-div detail-dontent-div1">
                    <div className="img-div">
                      <img
                        className="new-profile-image"
                        src={
                          profileDetails?.image
                            ? getImageUrl(profileDetails.image)
                            : profile
                        }
                        alt="Pic not found!"
                      />
                    </div>
                    <div className="detail-content-div">
                      <h3 className="accountname-h3 common-h3-h4">
                        {profileDetails?.firstName +
                          " " +
                          profileDetails?.lastName}
                      </h3>
                      <h4 className="dentist-h4 common-h3-h4 mt-2 text-grey mb-2">
                        Dentist
                      </h4>
                      <h4 className="join-h4 common-h3-h4 dentist-h4 text-grey">
                        Joined{" "}
                        {new Date(profileDetails?.createdAt).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </h4>
                    </div>
                  </div>
                  <div className=" detail-dontent-div2">
                    <h3 className="accountname-h3 common-h3-h4 dentist-h4">
                      This month
                    </h3>
                    <div className="bookings-earnings-div">
                      <div className="div1 common-div">Bookings:</div>
                      <div className="div2 common-div">
                        {data?.totalBookingsThisMonth}
                      </div>
                      <div className="div3 common-div">Earnings:</div>
                      <div className="div4 common-div">
                        £{data?.earningsThisMonth?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div className=" detail-dontent-div2">
                    <h3 className="accountname-h3 common-h3-h4 dentist-h4">
                      All Time
                    </h3>
                    <div className="bookings-earnings-div">
                      <div className="div1 common-div">Bookings:</div>
                      <div className="div2 common-div">
                        {data?.totalBookingsAllTime}
                      </div>
                      <div className="div3 common-div">Earnings:</div>
                      <div className="div4 common-div">
                        £{data?.totalEarningsAllTime?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="help-div same-property mt-4">
                  <div className="heading-div p-sm-0">
                    <h2 className="help-h2 common-h2">Help</h2>
                  </div>
                  <div className="bookings-earnings-div helpline-div">
                    <div className="div1 common-div">Helpline:</div>
                    <div className="div2 common-div">020 3880 6050</div>
                    <div className="div3 common-div">Email:</div>
                    <div className="div4 common-div">help@toothaid.co</div>
                  </div>
                  <div className="review-div">
                    {/* <button
                      className="submit-btn common-div"
                      type="submit"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Submit a Review
                    </button> */}

                    <button
                      className="submit-btn common-div"
                      onClick={() => navigate("/helpandsupport")}
                    >
                      Submit an issue
                    </button>
                    <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                              Submit Review
                            </h5>
                            <button
                              type="button"
                              class="btn-close"
                              id="close-modal"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <form onSubmit={submitReview}>
                            <div class="modal-body text-center ">
                              <label className="">Rating:</label>
                              <div className="d-flex justify-content-center">
                                <ReactStars className="hola-amigo"
                                  count={5}
                                  onChange={ratingChanged}
                                  size={30}
                                  color2={"#ffd700"}
                                  half={false}
                                  // value={5}
                                  value={state.rating}
                                  sty
                                />
                              </div>
                              <textarea
                                name=""
                                id=""
                                rows={5}
                                cols={30}
                                required
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    comment: e.target.value,
                                  })
                                }
                                className="p-2 input_ lead fw-light fs-6"
                                placeholder="Leave Comment..."
                              ></textarea>
                            </div>
                            <div class="modal-footer">
                              {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                              <button
                                htmType="button"
                                class=" submit-btn fw-bold py-1 px-5 "
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="request-div same-property">
                  <div className="heading-div p-sm-0">
                    <h2 className="request-h2 common-h2">New Requests</h2>
                  </div>
                  <div className="request-content-div">
                    <div className="booking-div">
                      <div className="circle">
                        <h3 className="h32-h3 common-div">
                          {data?.pendingBookings}
                        </h3>
                      </div>
                      <h4 className="pending-h4 common-div">
                        Pending Bookings
                      </h4>
                    </div>
                    <div className="earning-div detail-dontent-div2">
                      <h2 className="potential-h2 common-div">
                        {" "}
                        <span>£{data?.potentialEarnings} </span>Potential
                        Earnings
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="clients-div same-property mt-4">
                  <div className="heading-div p-sm-0">
                    <h2 className="client-h2 common-h2">Clients</h2>
                  </div>
                  <div className="request-content-div">
                    <div className="booking-div">
                      <div className="circle">
                        <h3 className="h32-h3 common-div">
                          {data?.clientsRegistered}
                        </h3>
                      </div>
                      <h4 className="pending-h4 common-div">
                        Clients Registered
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="setting-div same-property h-100">
                  <div className="heading-div p-sm-0">
                    <h2 className="setting-h2 common-h2">Availability Settings</h2>
                  </div>

                  <div className="setting-item">
                    <h5>Holiday Mode</h5>
                    <label className="switch">
                      <input type="checkbox"
                        checked={isHolidayMode}
                        onChange={() => setIsHolidayMode(!isHolidayMode)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>

                  <div className="data-div  earning-div detail-dontent-div2">
                    <div className="head-time">
                      <h6>Consultation Availability</h6>
                      <h6>Unavailable</h6>
                    </div>
                    <div className="availability-table">
                      {Object.keys(availability).map((day) => (
                        <div key={day} className="availability-day">
                          <div className="label-day">
                            <label>{day}:</label>
                          </div>
                          <div className="input-time-box">
                            {availability[day].map((timeSlot, index) => (
                              <div key={index} className="time-slot">
                                <div className="d-flex flex-row">
                                  <select
                                    value={timeSlot.start}
                                    onChange={(e) =>
                                      handleTimeChange(day, index, "start", e.target.value)
                                    }
                                    className="time-dropdown"
                                    disabled={isHolidayMode || availabilityStatus[day]}
                                  >
                                    {generateTimeOptions().map((time) => (
                                      <option key={time} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                  <span className="ms-2 me-2" style={{ color: "#000" }}>-</span>
                                  <select
                                    value={timeSlot.end}
                                    onChange={(e) =>
                                      handleTimeChange(day, index, "end", e.target.value)
                                    }
                                    className="time-dropdown"
                                    disabled={isHolidayMode || availabilityStatus[day]}
                                  >
                                    {generateTimeOptions().map((time) => (
                                      <option key={time} value={time}>
                                        {time}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="action-box">
                                  <div className="button-group">
                                    {index === availability[day].length - 1 && (
                                      <button
                                        type="button"
                                        onClick={() => addTimeSlot(day)}
                                        className="add-time-btn"
                                        disabled={isHolidayMode || availabilityStatus[day]}
                                      >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect width="16.9706" height="16.9706" rx="8.48528" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 24 12)" stroke="#1B95BC" stroke-width="1.5" />
                                          <path d="M12.1167 8.36722L12.1167 12.1172L8.3667 12.1172" stroke="#1B95BC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                          <path d="M15.7498 12L11.9998 12L11.9998 15.75" stroke="#1B95BC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                      </button>
                                    )}
                                    {index > 0 && index === availability[day].length - 1 && (
                                      <button
                                        type="button"
                                        className="add-time-btn minus-btton"
                                        onClick={() => removeTimeSlot(day, index)}
                                        disabled={isHolidayMode || availabilityStatus[day]}
                                      >
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <rect width="16.9706" height="16.9706" rx="8.48528" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 24 12)" stroke="#1B95BC" stroke-width="1.5" />
                                          <path d="M8.3667 12.1172L15.8667 12.1172" stroke="#1B95BC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                      </button>
                                    )}
                                  </div>

                
                                  <input
                                    type="checkbox"
                                    className="availability-checkbox"
                                    checked={availabilityStatus[day]}
                                    onChange={() => handleAvailabilityChange(day)}
                                    disabled={isHolidayMode}
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="review-div">
                    <button className="submit-btn common-div" onClick={handleDentistAvailability}>
                      Set Availability
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
}