import profile from "../../Assets/Images/defaultUser.jpg";
import dashboard from "../../Assets/Images/dashboard.svg";
import booking from "../../Assets/Images/bookings.svg";
import client from "../../Assets/Images/clients.svg";
import prescription from "../../Assets/Images/prescriptions.svg";
import account from "../../Assets/Images/account.svg";
import earning from "../../Assets/Images/earnings.svg";
import dashboard_2 from "../../Assets/Images/dashboard_2.svg";
import booking_2 from "../../Assets/Images/bookings_2.svg";
import clients_2 from "../../Assets/Images/clients_2.svg";
import earning_2 from "../../Assets/Images/earnings_2.svg";
import account_2 from "../../Assets/Images/account_2.svg";
import account_3 from "../../Assets/Images/help-support.svg";
import account_4 from "../../Assets/Images/help-support-dark.svg";
import prescription_2 from "../../Assets/Images/prescription_2.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProfilePic, profileUpload } from "../../Redux/Actions/user/userAll";
import { getImageUrl, options } from "../../Utils/commonFiles";

export default function Sidebar() {
  const dispatch = useDispatch();
  const [menuButton, setMenuButton] = useState(false);
  const [previewImg, setPreviewImg] = useState(profile);
  const profileDetails = useSelector((state) => state.consultaions.profilePic);

  const isHref = (pageStr, secondPageStr, thirdStr, fouthStr, fifthStr) => {
    if (
      window.location.href.includes(pageStr) ||
      window.location.href.split("/").includes(secondPageStr) ||
      window.location.href.split("/").includes(thirdStr) ||
      window.location.href.split("/").includes(fouthStr) ||
      window.location.href.split("/").includes(fifthStr)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleImageUpload = (e) => {
    setPreviewImg(URL.createObjectURL(e.target.files[0]));
    let formData = new FormData();
    formData.append("file", e.target.files[0]);
    dispatch(profileUpload(formData)).then(() => {
      getProfilePic();
    });
  };

  useEffect(() => {
    dispatch(getProfilePic());
  }, []);
  return (
    <>
      <div className="side bg-white position-lg-fixed py-lg-5 h-100 ">
        <div className="sidebar-col">
          <div className="text-center navbar-expand-lg navbar-light flex-column sidebar_content ">
            {/* <button className="navbar-toggler collapse" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button> */}
            <div
              id="navbarNavDropdown"
              className=" collapse navbar-collapse flex-column"
            >
              <div className="pic-name-div position-relative">
                <div className="profile-pic-div pt-2">
                  <img
                    className="show-img-profile"
                    src={
                      profileDetails?.image
                        ? getImageUrl(profileDetails?.image)
                        : previewImg
                    }
                    alt="Profile Image"
                    onError={(e) => (e.target.src = previewImg)} 
                  />

                </div>
                <div className="upload-input">
                  <input
                    onChange={handleImageUpload}
                    type="file"
                    className="img-upload-input"
                  />
                </div>
                <div className="name-div p-1">
                  <h1 className="name-h1 common text-capitalize">
                    {profileDetails?.firstName + " " + profileDetails?.lastName}
                  </h1>
                  <h2 className="member-h2 common">
                    Member Since{" "}
                    {new Date(profileDetails?.createdAt).toLocaleDateString(
                      "en-US",
                      options
                    )}
                  </h2>
                </div>
              </div>
              <div className="menu-div">
                <ul className=" menu-ul">
                  <li
                    className={
                      isHref("dashboard")
                        ? "dashboard menu-ul-li sidebar_tab_active"
                        : "dashboard menu-ul-li"
                    }
                  >
                    <div className="wrap">
                      <Link to="/dashboard">
                        <div className="img-div">
                          <img
                            src={isHref("dashboard") ? dashboard_2 : dashboard}
                            alt="Pic not found!"
                            className="icons"
                          />
                        </div>
                        <div className="headingname">
                          <h3
                            className={`heading ${isHref("dashboard") && "text-white"
                              }`}
                          >
                            Dashboard
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </li>
                  <li
                    className={
                      isHref("bookings") || isHref("consultation")
                        ? "bookings menu-ul-li sidebar_tab_active"
                        : "bookings menu-ul-li"
                    }
                  >
                    <div className="wrap">
                      <Link to="/bookings?view_type=Day">
                        <div className="img-div">
                          <img
                            src={
                              isHref("bookings") || isHref("consultation")
                                ? booking_2
                                : booking
                            }
                            alt="Pic not found!"
                          />
                        </div>
                        <div className="headingname">
                          <h3
                            className={`heading ${(isHref("bookings") || isHref("consultation")) &&
                              "text-white"
                              }`}
                          >
                            Bookings
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </li>
                  <li
                    className={
                      isHref("leads")
                        ? "leads menu-ul-li sidebar_tab_active"
                        : "leads menu-ul-li"
                    }
                  >
                    <div className="wrap">
                      <Link to="/leads?view_type=Day">
                        <div className="img-div">
                          <img
                            src={isHref("leads") ? earning_2 : earning}
                            alt="Pic not found!"
                          />
                        </div>
                        <div className="headingname">
                          <h3
                            className={`heading ${isHref("leads") && "text-white"
                              }`}
                          >
                            Leads
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </li>
                  <li
                    className={
                      isHref("client")
                        ? "myclients menu-ul-li sidebar_tab_active"
                        : "myclients menu-ul-li"
                    }
                  >
                    <div className="wrap">
                      <Link to="/clients">
                        <div className="img-div">
                          <img
                            src={isHref("client") ? clients_2 : client}
                            alt="Pic not found!"
                          />
                        </div>
                        <div className="headingname">
                          <h3
                            className={`heading ${isHref("client") && "text-white"
                              }`}
                          >
                            My Clients
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </li>
                  <li
                    className={
                      isHref("earnings")
                        ? "earnings menu-ul-li sidebar_tab_active"
                        : "earnings menu-ul-li"
                    }
                  >
                    <div className="wrap">
                      <Link to="/earnings">
                        <div className="img-div">
                          <img
                            src={isHref("earnings") ? earning_2 : earning}
                            alt="Pic not found!"
                          />
                        </div>
                        <div className="headingname">
                          <h3
                            className={`heading ${isHref("earnings") && "text-white"
                              }`}
                          >
                            Earnings
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </li>
                  <li
                    className={
                      isHref("prescription")
                        ? "earnings menu-ul-li sidebar_tab_active"
                        : "earnings menu-ul-li"
                    }
                  >
                    <div className="wrap">
                      <Link to="/prescription">
                        <div className="img-div">
                          <img
                            src={
                              isHref("prescription")
                                ? prescription_2
                                : prescription
                            }
                            alt="Pic not found!"
                          />
                        </div>
                        <div className="headingname">
                          <h3
                            className={`heading ${isHref("prescription") && "text-white"
                              }`}
                          >
                            Prescriptions
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </li>
                  <li
                    className={
                      isHref(
                        "myaccount",
                        "personal_information",
                        "bank-detail",
                        "contact_details",
                        "account_settings"
                      )
                        ? "myaccount menu-ul-li sidebar_tab_active"
                        : "myaccount menu-ul-li"
                    }
                  >
                    <div className="wrap">
                      <Link to="/myaccount">
                        <div className="img-div">
                          <img
                            src={
                              isHref(
                                "myaccount",
                                "personal_information",
                                "bank-detail",
                                "contact_details",
                                "account_settings"
                              )
                                ? account_2
                                : account
                            }
                            alt="Pic not found!"
                          />
                        </div>
                        <div className="headingname">
                          <h3
                            className={`heading ${isHref(
                              "myaccount",
                              "personal_information",
                              "bank-detail",
                              "contact_details",
                              "account_settings"
                            ) && "text-white"
                              }`}
                          >
                            My Account
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </li>
                  <li
                    className={
                      isHref("helpandsupport")
                        ? "helpandsupport menu-ul-li sidebar_tab_active"
                        : "helpandsupport menu-ul-li"
                    }
                  >
                    <div className="wrap">
                      <Link to="/helpandsupport">
                        <div className="img-div">
                          <img
                            src={
                              isHref("helpandsupport") ? account_4 : account_3
                            }
                            alt="Pic not found!"
                          />
                        </div>
                        <div className="headingname">
                          <h3
                            className={`heading ${isHref("helpandsupport") && "text-white"
                              }`}
                          >
                            Help & Support
                          </h3>
                        </div>
                      </Link>
                    </div>
                  </li>
                </ul>
                <button
                  // onClick={handleSignOut}
                  // onClick={()=>navigate('/')}
                  className=" white-btn mt-0 signOut_btn topbar_signout_btn d-block d-lg-none"
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
