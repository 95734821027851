import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const RedirectModal = ({ viewVal, setShowVal, handleRedirect }) => {
  const handleClose = () => {
    setShowVal(false);
    sessionStorage.removeItem('tokenModalShown'); 
  };

  return (
    <Modal
      show={viewVal}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="admin-popup provisional-popup detailed_popup"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
         
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="table-responsive table-default-design text_inner">
          <h4>Please Add Clynxx Chamber Token</h4>
          <p>
            You need to add your Clynxx Chamber Token before you can add a prescription. 
            Please go to your account settings to add the token.
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-column">
          <div className="btn_submit mb-3">
            <button
              onClick={handleRedirect}
              className="btn btn-primary btn-custom btn-lg w-100 submit_btn confirmation_btn"
            >
              Go to My Account
            </button>
          </div>
          <div className="btn_submit">
            <button
              onClick={handleClose}
              className="btn btn-primary cancel-btn"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RedirectModal;