import { Container, Row, Col, Card, ListGroup, Modal } from "react-bootstrap";
import profile from "../../Assets/Images/photouser.png";
import lessarrow from "../../Assets/Icons/lessarrow.svg";
import previousarr from "../../Assets/Icons/previous.svg";
import greatarrow from "../../Assets/Icons/arrow.svg";
import Main from "../Main/Main";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { getLeads, confirmBooking } from "../../Redux/Actions/user/userAll";
import { Link, useLocation } from "react-router-dom";
import {
  formatConsultationDate,
  formatDate,
  formatDateToDMY,
  getDateDescription,
  getFormattedTime,
  getImageUrl,
  isToday,
  timeAgo,
  timeAgo1,
} from "../../Utils/commonFiles";
import moment from "moment";
import ConfirmationModal from "./ConfiramtionModal";
import { toast } from "react-toastify";

export default function Leads() {
  const dispatch = useDispatch();
  const location = useLocation();

  const Image_url = process.env.REACT_APP_IMG_URL + "public/uploads/";
  const data = useSelector((state) => state?.consultaions?.leads?.data);
  const queryParams = new URLSearchParams(location.search);
  const viewType = queryParams.get("view_type");
  const limit = 10;

  const [page, setPage] = useState("1");
  const [search, setSearch] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentBooking, setCurrenBooking] = useState(0);
  const [previousarrow, setPreviousArrow] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [currentBookingId, setCurrentBookingId] = useState(null);
  const [message, setMessage] = useState(null);

  const handleClose = () => {
    setShowModal(false);
    setMessage(null);
  };
  const handleShow = () => setShowModal(true);

  // const adjustDate = (days) => {
  //   if (days < 0 && isToday(currentDate)) return;
  //   setCurrentDate((prevDate) => {
  //     const newDate = new Date(prevDate);
  //     newDate.setDate(newDate.getDate() + days);
  //     return newDate;
  //   });
  // };

  const adjustDate = (days) => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + days);

      if (isToday(newDate)) {
        setPreviousArrow(true);
      } else {
        setPreviousArrow(false);
      }

      return newDate;
    });
  };

  const getData = async () => {
    let data = {
      page,
      limit,
    };
    if (viewType === "Day") data["date"] = formatDateToDMY(currentDate, true);
    try {
      dispatch(getLeads(data));
    } catch (error) {}
  };

  const ConfirmBooking = async (id) => {
    try {
      let res = await dispatch(confirmBooking({ id: id })).then((res) => {
        if (res?.payload?.success) {
          // toast.success(res?.payload?.message);
          // setMessage(res?.payload?.message);
          setMessage("Confirmed");

          setCurrenBooking(0);
          getData();
          handleShow();
        } else {
          toast.error(res?.payload?.message)
        }
      });
    } catch {}
  };

  // const ConfirmBooking = async (id) => {
  //   setCurrentBookingId(id);
  //   handleShow();
  // };

  // const handleConfirm = async () => {
  //   try {
  //     await dispatch(confirmBooking({ id: currentBookingId }));
  //     setCurrentBookingId(null);
  //     setCurrenBooking(0);
  //     getData();
  //   } catch {}
  //   handleClose();
  // };

  // const ConfirmBooking = (id) => {
  //   setCurrentBookingId(id);
  //   handleConfirm(); // Call handleConfirm to process the booking confirmation
  // };

  // const handleConfirm = async () => {
  //   try {
  //     const res = await dispatch(confirmBooking({ id: currentBookingId }));
  //     if (res?.payload?.success) {
  //       setMessage("Confirmed"); // Set the message to show in the modal
  //       setShowModal(true); // Show the modal with confirmation message
  //       setCurrentBookingId(null); // Reset the current booking ID if needed
  //       setCurrenBooking(0); // Assuming this is part of your state management
  //       getData(); // Refresh data if needed
  //     }
  //   } catch (error) {
  //     console.error("Booking confirmation failed:", error);
  //   }
  // };

  useEffect(() => {
    getData();
  }, [viewType, currentDate]);

  // const handleArrowClick = () => {
  //   setPreviousArrow(!previousarrow); // Toggle between arrows
  // };

  return (
    <Main>
      <div className="leads">
        <Container fluid className="p-0">
          <div className="heading-booking-div">
            <h2 className="booking-h2">New Requests</h2>
          </div>
          <Row className="mt-2 justify-content-between">
            <Col md={2} className="calender-set">
              <div
                className={`today-div d-flex justify-content-${
                  viewType === "Day" ? "between" : "center"
                } my-3`}
              >
                {viewType === "Day" && (
                  <div className="arrow-div" onClick={() => adjustDate(-1)}>
                    <img className="" src={lessarrow} alt="Pic not Found!" />
                  </div>
                )}
                <h3 className="text-center">
                  {viewType === "Day"
                    ? `${getDateDescription(currentDate)}`
                    : "All Requests"}
                </h3>
                {viewType === "Day" && (
                  <div
                    className="arrow-div"
                    onClick={() => {
                      adjustDate(1);
                    }}
                  >
                    <img className="" src={greatarrow} alt="Pic not Found!" />
                  </div>
                )}
              </div>
              <Card className="border-0 list-calender">
                <ListGroup>
                  {data?.allBookings?.length ? (
                    data?.allBookings.map((res, index) => (
                      <ListGroup.Item
                        style={{ cursor: "pointer" }}
                        className={`px-0 ${
                          index === currentBooking ? "active-listing" : ""
                        }`}
                        key={index}
                        onClick={() => setCurrenBooking(index)}
                      >
                        <div className="time-set-list">
                          {/* <img
                            className="ppic p-0"
                            src={profile}
                            alt="Pic not Found!"
                          /> */}

                          <img
                            src={res.image ? Image_url + res.image : profile}
                            alt="Pic not found!"
                            className="list-image"
                          />

                          <div className="">
                            <p>{res.firstName + " " + res.lastName}</p>
                            <div>
                              <p>
                                <i>
                                  {" "}
                                  {formatConsultationDate(
                                    res?.consultationDate
                                  )}
                                </i>
                              </p>
                              <p>{getFormattedTime(res?.consultationDate)}</p>
                            </div>
                          </div>
                        </div>
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item>
                      <Row>
                        <Col md={12}>
                          <p className="text-center">No Clients Found</p>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}
                </ListGroup>
                <div className="text-center view-div-set ">
                  {viewType === "Day" ? (
                    <Link
                      to="/leads?view_type=All"
                      className=" text-decoration-underline"
                    >
                      View All Days
                    </Link>
                  ) : (
                    <Link
                      to="/leads?view_type=Day"
                      className=" text-decoration-underline"
                    >
                      Day View
                    </Link>
                  )}
                </div>
              </Card>
            </Col>
            {data?.allBookings?.length ? (
              <Col md={10} className="bg-white p-2 mt-3 ad-card-prop">
                <div className="main-content d-flex g-3">
                  <div className="img-div p-3">
                    <img
                      className="ppic-lg new-profile-image"
                      src={
                        data?.allBookings[currentBooking]?.image
                          ? getImageUrl(
                              data?.allBookings[currentBooking]?.image
                            )
                          : profile
                      }
                      alt="Pic not Found!"
                    />
                  </div>
                  <div className="maincontent-div px-3 w-100 py-4">
                    <div className="div12 d-flex flex-row-reverse justify-content-between g-3">
                      <div className="name-div1">
                        <p className="call-p text-end fs-6 ">
                          {timeAgo1(
                            data?.allBookings[currentBooking]?.bookingsdata
                              ?.createdAt
                          )}
                        </p>
                      </div>
                      <div className="name-div2 mt-3">
                        <h5 className="same-properties-heading name-h5 fs-5 text-capitalize">
                          {data?.allBookings[currentBooking]?.firstName +
                            " " +
                            data?.allBookings[currentBooking]?.lastName}
                        </h5>
                        <h5 className="same-properties-heading  urgent-p">
                          Urgent Consultation Request
                        </h5>
                        <h5 className="same-properties-heading pt-2">
                          {`${
                            data?.allBookings[currentBooking]?.address
                              ?.townCity ?? ""
                          }   ${
                            data?.allBookings[
                              currentBooking
                            ]?.address?.postCode?.split(" ")[0] ?? ""
                          }`}
                        </h5>
                        <p className="p1 pt-3">
                          Contact <span className="orange "> URGENTLY</span>{" "}
                        </p>
                        <p className="p1">
                          Earnings{" "}
                          <span className="green">
                            {" "}
                            {/* £{data?.allBookings[currentBooking]?.price} */}
                            £ 15.61
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="detials-div1 d-flex flex-row justify-content-between mt-3">
                      <h5 className=" details-h5 ">Details</h5>
                    </div>
                    <div className=" mt-3 ">
                      <div className="">
                        <div>
                          <h6 className="booking-h6">
                            {" "}
                            Booking Date:{" "}
                            <span className="span2">
                              {" "}
                              {formatDate(
                                data?.allBookings[currentBooking]
                                  ?.consultationDate
                              )}{" "}
                            </span>{" "}
                          </h6>
                          <h6 className="booking-h6">
                            {" "}
                            Booking Time:{" "}
                            <span className="span2">
                              {" "}
                              {getFormattedTime(
                                data?.allBookings[currentBooking]
                                  ?.consultationDate
                              )}{" "}
                            </span>{" "}
                          </h6>
                        </div>
                        <div className="mt-4 text-left">
                          <button
                            className="d-block launch-btn border border-0 rounded"
                            type="submit"
                            onClick={() =>
                              ConfirmBooking(
                                data?.allBookings[currentBooking]
                                  ?.consultationId
                              )
                            }
                          >
                            Confirm Booking
                          </button>
                        </div>
                        {/* <div>
                        <h6 className='booking-h6'> Contact Email: <span className='span2'>simonjames@email.com </span> </h6>
                        <h6 className='booking-h6'> Contact Number:<span className=' fw-bold ms-3 '>(+44) 7477 202 151 </span> </h6>
                      </div> */}
                      </div>
                      {/* <div className='text-end'>Update</div> */}
                    </div>
                  </div>
                </div>
              </Col>
            ) : (
              <div className="col-md-10 position-relative">
                <div className="data-not-found">
                  <p>
                    No requests right now. We'll notify you when new requests
                    appear
                  </p>
                </div>
              </div>
            )}
          </Row>
        </Container>
      </div>
      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showModal}
        message={message}
        onCancel={handleClose}
      />
    </Main>
  );
}
