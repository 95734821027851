const HOST_NAME = process.env.REACT_APP_IMG_URL;

export function formatDateToDMY(dateString, type) {
  const date = new Date(dateString);

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  if (type) {
    return `${year}-${month}-${day}`;
  } else {
    return `${day}/${month}/${year}`;
  }
}

// export function getFormattedTime(dateString) {
//   const date = new Date(dateString);

//   const options = {
//     hour: "2-digit",
//     minute: "2-digit",
//     hour12: true,
//   };

//   const formatter = new Intl.DateTimeFormat("en-US", options);
//   return formatter?.format(date);
// }

export function getFormattedTime(dateString) {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat("en-US", options);
  return formatter.format(date);
}

export function formatDate(inputDate) {
  // Convert the input to a Date object if it's not already
  const date = new Date(inputDate);

  // Check if date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = monthsOfYear[date.getMonth()];

  // Get the appropriate suffix for the day of the month (st, nd, rd, th)
  const suffix =
    dayOfMonth % 10 === 1 && dayOfMonth !== 11
      ? "st"
      : dayOfMonth % 10 === 2 && dayOfMonth !== 12
      ? "nd"
      : dayOfMonth % 10 === 3 && dayOfMonth !== 13
      ? "rd"
      : "th";

  // Check if the provided date is today
  const today = new Date();
  const isToday = date.toDateString() === today.toDateString();

  return `${dayOfWeek}, ${dayOfMonth}${suffix} ${month}${
    isToday ? " (Today)" : ""
  }`;
}

export function getDateDescription(inputDate) {
  const date = new Date(inputDate); // Convert inputDate to a Date object

  // Create Date objects for today, tomorrow, and the day after tomorrow
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const dayAfterTomorrow = new Date();
  dayAfterTomorrow.setDate(today.getDate() + 2);

  // Normalize dates to ignore the time part for comparison
  const normalizeDate = (date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate());

  // Compare the normalized dates
  if (normalizeDate(date).getTime() === normalizeDate(today).getTime()) {
    return "Today";
  } else if (
    normalizeDate(date).getTime() === normalizeDate(tomorrow).getTime()
  ) {
    return "Tomorrow";
  } else {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Return the date in the format date-monthName-year
    const day = date.getDate();
    const monthName = monthNames[date.getMonth()]; // Get month name
    const year = date.getFullYear();
    return `${day} ${monthName} ${year}`;
  }
}
export const isToday = (date) => {
  const today = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

// export function timeAgo(inputTime) {
//     const date = new Date(inputTime);
//     const now = new Date();
//     const seconds = Math.floor((now - date) / 1000);

//     const intervals = [
//         { label: 'year', seconds: 31536000 },
//         { label: 'month', seconds: 2592000 },
//         { label: 'week', seconds: 604800 },
//         { label: 'day', seconds: 86400 },
//         { label: 'hour', seconds: 3600 },
//         { label: 'minute', seconds: 60 },
//         { label: 'second', seconds: 1 }
//     ];

//     for (const interval of intervals) {
//         const count = Math.floor(seconds / interval.seconds);
//         if (count >= 1) {
//             return count === 1
//                 ? `A ${interval.label} ago`
//                 : `${count} ${interval.label}s ago`;
//         }
//     }

//     return 'just now';
// }

export function timeAgo1(inputTime) {
  const date = new Date(inputTime);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  if (seconds < 300) {
    return "Just now";
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
    const days = Math.floor(seconds / 86400);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }
}


export function getImageUrl(path) {
  return HOST_NAME + "public/uploads/" + path;
}
export const options = { year: "numeric", month: "long" };

export function formatConsultationDate(dateString) {
  if (dateString) {
    const date = new Date(dateString);

    const options = { weekday: "short" };
    const day = date.toLocaleDateString("en-US", options);

    const dateOfMonth = String(date.getDate()).padStart(2, "0");

    return `${day}, ${dateOfMonth}`;
  }
}

export function shouldShowContactInfo(consultationDateString) {
  if (consultationDateString) {
    const consultationDate = new Date(consultationDateString);
    const currentDate = new Date();

    const diffInMs = consultationDate - currentDate;

    const diffInHours = diffInMs / (1000 * 60 * 60);

    return diffInHours <= 2 && diffInHours >= 0;
  }
}


export const MedicalQuestions = [
  {
    ques: 1,
    quest: "1) Are you registered with a GP Practice?",
    answer: "",
    inputField: "",
    subQues: "Please give details (Name, Address, Postcode, Contact number)",
  },
  {
    ques: 2,

    answer: "",
    inputField: "",
    quest:
      "2) Do you authorise us to contact your next of kin in an emergency?",
    subQues: "Please enter their: Name, Relationship & Contact Number.",
  },
  // {
  //   ques: 3,
  //   answer: "",
  //   inputField: "",
  //   quest: "3) Have you visited a dentist in the last 5 years?",
  //   subQues:
  //     "When and what did you have done? (Provide short summary if possible)",
  // },
  {
    ques: 3,
    answer: "",
    inputField: "",
    quest: "3) Are you Pregnant?",
    subQues: "If yes how many weeks pregnant are you",
  },
  {
    ques: 4,

    answer: "",
    inputField: "",
    quest:
      "4) Are you attending or receiving any form of treatment from a doctor specialist, hospital or clinic?",
    subQues: "Please give details (treatment/ doctor/ hospital/ clinic etc.)",
  },
  {
    ques: 5,

    answer: "",
    inputField: "",
    quest: "5) Are you taking any prescribed medication? ",
    subQues: "Tablets, creams, inhalers,injections, contraceptives or HRT",
  },
  {
    ques: 6,

    answer: "",
    inputField: "",
    quest:
      "6) Do you have allergies to any medicines (e.g. antibiotics), materials (e.g.latex/rubber) or foods?",
    subQues: "Please provide list",
  },
  {
    ques: 7,
    answer: "",
    inputField: "",
    quest: "7) Do you currently carry a medical warning card?",
    subQues: "Please provide details",
  },
  // {
  //   ques: 9,

  //   answer: "",
  //   inputField: "",
  //   quest: "9) Do you suffer from hay fever, eczema or any other allergy?",
  //   subQues: "Please provide details",
  // },
  {
    ques: 8,
    quest: "8) Do you or have you ever suffered any of the following conditions?\ni) Heart conditions (For example: angina, a heart attack)\nii) High or low blood pressure\niii) Stroke\niv) Chest conditions (For example: bronchitis, asthma)\nv) Bone conditions (For example: osteoarthritis or osteoporosis)",
    answer: "",
    inputField: "",
    subQues: "Please provide details",
  },
  {
    ques: 9,
    quest: "9) Do you regularly drink alcohol, smoke or use other tobacco products?",
    answer: "",
    inputField: "",
    subQues: "If yes what specifically and frequency",
  },
  // {
  //   ques: 11,
  //   answer: "",
  //   inputField: "",
  //   quest: "11) Have you ever had a heart attack or stroke?",
  //   subQues: "Please provide details",
  // },
  // {
  //   ques: 12,
  //   answer: "",
  //   inputField: "",
  //   quest: "12)Have you ever had heart surgery or a pacemaker fitted?",
  //   subQues: " (If yes provide freetext box to give details)",
  // },
  // {
  //   ques: 12,
  //   answer: "",
  //   inputField: "",
  //   quest: "12) Do vou have bronchitis, asthma or any other chest condition?",
  //   subQues: "Please provide details",
  // },
  {
    ques: 10,
    answer: "",
    inputField: "",
    quest:
      "10) Do you take steroids or have you taken steroids in the last 2 yrs?",
    subQues: "Please provide details",
  },
  {
    ques: 11,
    answer: "",
    inputField: "",
    quest: "11) Do you have diabetes or does anyone in vour immediate family?",
    subQues: "Please provide details",
  },
  // {
  //   ques: 16,
  //   answer: "",
  //   inputField: "",
  //   quest: "16) Have you ever had rheumatic fever or cholera (St Vitus Dance)?",
  //   subQues: "Please provide details",
  // },
  {
    ques: 12,
    answer: "",
    inputField: "",
    quest:
      "12) Have you had liver disease, Kidney disease hepatitis B, hepatitis C, HIV?",
    subQues: "Please provide details",
  },
  {
    ques: 13,
    answer: "",
    inputField: "",
    quest:
      "13) Do you have fainting attacks, giddiness, blackouts or epilepsy?",
    subQues: "Please provide details",
  },
  // {
  //   ques: 14,
  //   answer: "",
  //   inputField: "",
  //   quest: "14) Do you smoke or chew tobacco products?",
  //   subQues: "If yes what specifically and for how long in years",
  // },
  // {
  //   ques: 18,
  //   answer: "",
  //   inputField: "",
  //   quest: "18) Do you drink alcohol?",
  //   subQues: "If yes how many units per week",
  // },
  // {
  //   ques: 19,
  //   answer: "",
  //   inputField: "",
  //   quest:
  //     "19) Do you have a history of bruising easily or persistent bleeding following an injury or past tooth extraction?",
  //   subQues: "Please provide details",
  // },
  // {
  //   ques: 22,
  //   answer: "",
  //   inputField: "",
  //   quest: "22) Do vou grind vour teeth?",
  // },
  // {
  //   ques: 20,
  //   answer: "",
  //   inputField: "",
  //   quest:
  //     "20) Have you ever been fitted with a medical device e.g. pacemaker, stent, or joint replacement?",
  //   subQues: "Please provide details",
  // },
  // {
  //   ques: 21,
  //   answer: "",
  //   inputField: "",
  //   quest:
  //     "21) Have you ever had a bad reaction to general or local anaesthetic?",
  //   subQues: "Please provide details",
  // },
  // {
  //   ques: 25,
  //   answer: "",
  //   inputField: "",
  //   quest:
  //     "25) Would you like your dentist to provide information on routine dental care such as Tooth Whitening, Cosmetic dentistry, or Tooth replacement options?",
  //   subQues: " Please provide details",
  // },
  {
    ques: 14,
    answer: "",
    inputField: "",
    quest:
      "14) Are there any aspects of your health or concerns that you think the dentist should know about?",
    subQues: " Please provide details",
  },
  // {
  //   ques: 23,
  //   answer: "",
  //   inputField: "",
  //   quest:
  //     "23) Do you have any bone conditions? For Example osteoarthritis / osteoporosis",
  //   // subQues: " (If other provide freetext box to give details)",
  //   subQues: "Please provide details",
  // },
];