// import './myaccount.css'
import { useEffect, useState } from 'react';
import Main from '../Main/Main'
import { Link } from 'react-router-dom'
import { updateProfile } from '../../Redux/Actions/user/userAll';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';


export default function Myaccount() {

  const [tokenInput, setTokenInput] = useState(''); 
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch(); 

    const profileDetails = useSelector((state) => state.consultaions.profilePic);

  const handleTokenUpdate = () => {
    setLoading(true); 

    const addClynxToken = {
      personalInfo: {
        dentistClynxxChamber_token: tokenInput,
      },
    };

    dispatch(updateProfile(addClynxToken))
      .then((res) => {
        console.log(res, "sdfsdfsdfsdf")
        if (res?.payload?.user?.success) { 
          // toast.success('Clynxx Chamber Token updated successfully!');
          sessionStorage.removeItem('tokenModalShown'); 
        }
      })
      .catch((err) => {
        console.error('Error updating token:', err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (profileDetails?.dentistClynxxChamber_token) {
      setTokenInput(profileDetails.dentistClynxxChamber_token);
    }
  }, [profileDetails?.dentistClynxxChamber_token]);


  return (
    <Main>
      <div class="col-xl-9 col-12 ">
        <div class="new_consultation_booking common_shadow bg-white">
          <h2 class=" commom-prop common_title padding_36">My Account</h2>
          <div class="row p-0"><div class="col-12">
            <div class="personal_details personal_history_accordian px-3">
              <div class="card my-3"><div class="card-body p-0">
                <Link to='/personal_information' class="d-flex account_info">
                  <h6 class=" commom-prop mb-0">Personal Information</h6>
                  <p class="update-history small_para">View and Update Your Personal Information</p>
                </Link>
              </div>
              </div>
              <div class="card my-3">
                <div class="card-body p-0">
                  <Link to='/bank-detail' class="d-flex account_info" >
                    <h6 class=" commom-prop mb-0">Bank Details</h6>
                    <p class="update-history small_para">Update your bank details where we’ll pay your earnings</p>
                  </Link>
                </div>
              </div>
              <div class="card my-3"><div class="card-body p-0">
                <Link to='/contact_details' class="d-flex account_info" >
                  <h6 class=" commom-prop mb-0">Contact Settings</h6><p class="update-history small_para">Update your contact details and communication preferences</p>
                </Link>
              </div>
              </div>
              <div class="card my-3">
                <div class="card-body p-0">
                  <Link class="d-flex account_info" to="/account_settings">
                    <h6 class=" commom-prop mb-0">Account Settings</h6>
                    <p class="update-history small_para">Delete account</p>
                  </Link>
                </div>
              </div>

              <div className="card rounded my-3">
                <div className="card-body p-0  account_info accordian_head" id="heading4">
                  <h5 className="mb-0">
                    <div
                      className=" d-flex personal_inner_sec  collapsed  "
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse4"
                      aria-expanded="true"
                      aria-controls="collapse4"
                      type="button"
                    >
                      {/* <Link class="d-flex account_info" to=""> */}
                      <h6 class=" mb-0">Connect Clynxx</h6>
                      <p class="update-history small_para">Add your Clynxx Chamber Token</p>
                      {/* </Link> */}
                    </div>
                  </h5>
                </div>

                <div
                  id="collapse4"
                  className="collapse"
                  aria-labelledby="heading4"
                  data-parent="#accordion"
                >
                  <div>
                    <div className="card-body p-3">
                      <div className="d-flex justify-content-evenly align-items-center">
                        <p class=" mb-0">Add Token</p>

                        <input
                          id="tokenInput"
                          type="text"
                          className="form-control token-input"
                          placeholder="Type here"
                          value={tokenInput}
                          onChange={(e) => setTokenInput(e.target.value)}
                        />
                        <button
                          className=" update_btn text-white p-2 btn"
                          onClick={handleTokenUpdate}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </Main>
  )
}